body {
  overflow-y: hidden;
  /* Hide vertical scrollbar */
  margin: 0;
  color: #1F1F1F;
  font-size: 14px;
  font-family: Helvetica Neue LT W05_55 Roman;
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';

}


.ant-list-items>li {
  line-height: 30.5px;
}